import { alphaUrl, ajaxApi } from '@/utils/request';

/** 参数信息 */
export type ParamInfo = {
  /** 邮箱 */
  email: string;
  /** 姓名 */
  name: string;
  /** 手机号 */
  phone: string;
  /** 标题 */
  title: string;
  /** 微信二维码 */
  wxQrUrl: string;
};

/** 获取系统参数 */
export const getSysParam = () => ajaxApi('get', alphaUrl + '/sys/param/contact') as Promise<ParamInfo[]>;
/** 获取oss文件 */
export const getOssFile = (fileId: string) =>
  ajaxApi('get', alphaUrl + '/sys/oss/access', { fileId }) as Promise<string>;
