/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-job': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M11.366 2.706V0H2.708v2.706H0V14h14V2.706h-2.634zm-7.133-1.38h5.609v1.38h-5.61v-1.38zm8.28 6.78H8.042v1.365H5.958V8.106h-4.47v-1.73h11.025v1.73z" _fill="#DEDDDD" fill-rule="nonzero"/><path pid="1" _stroke="#919191" stroke-width="1.1" d="M3.5 2.706V.718h7.113v1.988"/></g>'
  }
})
