/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-word': {
    width: 24,
    height: 30,
    viewBox: '0 0 24 30',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M.837 0H16.53L24 7.55v21.613a.837.837 0 01-.837.837H.837A.837.837 0 010 29.163V.837C0 .375.375 0 .837 0z" _fill="#4A8DFF"/><path pid="1" d="M16.83 0v6.49c0 .446.36.809.802.809h6.352L16.831 0z" _fill="#E5F0FF"/><path pid="2" d="M18.295 23.323c.691.764 1.952.27 1.952-.764V10.52H18v9.12l-4.463-4.933a1.116 1.116 0 00-1.659 0l-4.463 4.932V10.52H5.17v12.04c0 1.034 1.26 1.528 1.952.764l5.587-6.174 5.586 6.174z" _fill="#FFF"/></g>'
  }
})
