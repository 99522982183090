/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-risk': {
    width: 21,
    height: 19,
    viewBox: '0 0 21 19',
    data: '<g fill-rule="nonzero" _fill="none"><path pid="0" d="M14.977 18.979H1.68c-.924 0-1.676-.672-1.676-1.496V1.502C.003.677.755.006 1.68.006h13.298c.924 0 1.676.671 1.676 1.496V3.8c0 .337-.283.61-.634.61a.622.622 0 01-.634-.61V1.502c0-.132-.169-.28-.408-.28H1.68c-.242 0-.41.148-.41.28V17.48c0 .132.168.279.41.279h13.298c.242 0 .408-.147.408-.279v-.944c0-.337.284-.61.634-.61.35 0 .634.273.634.61v.944c0 .827-.752 1.499-1.676 1.499z" _fill="#000"/><path pid="1" d="M12.763 6.006H3.894a.625.625 0 01-.634-.612c0-.338.284-.613.634-.613h8.865c.35 0 .634.275.634.613a.624.624 0 01-.63.612zm-3.168 4.26h-5.7a.625.625 0 01-.635-.613c0-.337.284-.613.634-.613h5.701c.35 0 .634.276.634.613a.625.625 0 01-.634.613zm0 4.26h-5.7c-.351 0-.635-.26-.635-.598 0-.337.284-.597.634-.597h5.701c.35 0 .634.26.634.597 0 .337-.283.598-.634.598z" _fill="#000"/><path pid="2" d="M19.948 14.535H13.17a1.04 1.04 0 01-.876-.481 1.074 1.074 0 01-.045-1.076l3.391-6.414a1.039 1.039 0 011.842 0l3.39 6.414c.179.34.163.751-.044 1.076-.194.3-.523.481-.88.481zm-6.456-1.22h6.135L16.56 7.511l-3.069 5.804z" _fill="#0064C8"/><path pid="3" d="M16.57 11.489c-.35 0-.637-.273-.637-.61V9.662c0-.337.287-.61.638-.61.35 0 .637.273.637.61v1.217c0 .337-.287.61-.637.61zm-.009 1.51a.633.633 0 01-.644-.621c0-.344.29-.62.644-.62.357 0 .647.28.647.62a.637.637 0 01-.647.622z" _fill="#000"/></g>'
  }
})
