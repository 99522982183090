import { QueryPage, QueryPageResult } from './index'
import { alphaUrl, ajaxApi } from '@/utils/request'
import { Status } from '@/utils/interface'

export interface IOfficeInfo {
  /** 部门ID */
  officeId: string
  /** 部门名称 */
  officeName: string
  /** 部门主管id */
  officeAdmin: string
  /** 部门主管姓名 */
  officeAdminName: string
  /** 上级部门ID */
  parentId: string
  /** 上级部门名称 */
  parentName: string
  /** 背调订单数量 */
  orderNum: number
  /** 候选人数量 */
  candidateNum: number
  /** 有效职位数量 */
  positionNum: number
  /** 状态 */
  status: Status
  /** 描述 */
  description: string
  children: IOfficeInfo[]
}
export interface MOfficeInfo extends IOfficeInfo {
  /** 所有上级部门ID */
  parentIds?: string[]
}

type OfficeQueryPage = QueryPage & Partial<IOfficeInfo>

/** 获取信息 */
export const getOfficeInfo = (params: Pick<IOfficeInfo, 'officeId'>) =>
  ajaxApi('get', alphaUrl + '/office/query/id', params) as Promise<IOfficeInfo>

/** 返回客户所有部门（不含根部门）的列表信息 */
export const getOfficeList = () => ajaxApi('get', alphaUrl + '/office/query/list') as Promise<IOfficeInfo[]>

/** 根据层级返回客户所有部门的信息 */
export const getOfficeTree = () => ajaxApi('get', alphaUrl + '/office/query/tree') as Promise<IOfficeInfo[]>

/** 新增部门 */
export const addOffice = (params: IOfficeInfo) =>
  ajaxApi('post', alphaUrl + '/office/add', params) as Promise<IOfficeInfo['officeId']>

/** 新增部门 */
export const updateOffice = (params: IOfficeInfo) =>
  ajaxApi('post', alphaUrl + '/office/update', params).then(() => params.officeId)

/** 新增/更新部门 */
export const saveOffice = (params: IOfficeInfo) => {
  return params.officeId ? updateOffice(params) : addOffice(params)
}
/** 删除部门 */
export const removeOffice = (id: IOfficeInfo['officeId']) =>
  ajaxApi('post', alphaUrl + '/office/delete', { officeId: id })
