import { render, staticRenderFns } from "./XHeader.vue?vue&type=template&id=d420df44&scoped=true"
import script from "./XHeader.vue?vue&type=script&lang=ts"
export * from "./XHeader.vue?vue&type=script&lang=ts"
import style0 from "./XHeader.vue?vue&type=style&index=0&id=d420df44&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d420df44",
  null
  
)

export default component.exports