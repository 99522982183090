import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { IUserInfo, getAccountList } from '@/api/users'
import { getJobList } from '@/api/jobs'
import { getOfficeList } from '@/api/office'
import type { IOption } from '@/utils/interface'

export interface IOptionState {
  /** 账号数据 */
  acctList: IOption[]
  /** 职位数据 */
  jobList: IOption[]
  /** 部门数据 */
  officeList: IOption[]
}

@Module({ dynamic: true, store, name: 'option' })
class Option extends VuexModule implements IOptionState {
  acctList = [] as IOption<IUserInfo>[]
  jobList = [] as IOption[]
  officeList = [] as IOption[]

  /** 更新账号数据 */
  @Mutation
  private ACCT_LIST(ls: IOption[]) {
    this.acctList = ls
  }

  /** 更新职位数据 */
  @Mutation
  private JOB_LIST(ls: IOption[]) {
    this.jobList = ls
  }

  /** 更新部门数据 */
  @Mutation
  private OFFICE_LIST(ls: IOption[]) {
    this.officeList = ls
  }

  /** 获取账号数据 */
  @Action
  public getAccountList() {
    /** 所有账号数据 */
    getAccountList({ officeId: '', isChildrenInclude: true }).then(res => {
      const ls = res.map(i => ({ text: i.name, value: i.acctId, _origin: i }))
      this.ACCT_LIST(ls)
    })
  }

  /** 获取最新职位数据 */
  @Action
  public getJobList() {
    getJobList().then(res => {
      const ls = res.map(i => ({ text: i.positionName, value: i.positionId }))
      this.JOB_LIST(ls)
    })
  }

  /** 获取部门数据 */
  @Action
  public getOfficeList() {
    getOfficeList().then(res => {
      const ls = res.map(i => ({ text: i.officeName, value: i.officeId }))
      this.OFFICE_LIST(ls)
    })
  }
}

/** 选项状态管理 */
export const OptionModule = getModule(Option)
