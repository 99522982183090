
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'InputSearch',
})
export default class extends Vue {
  @Prop({ default: '请输入内容' }) placeholder!: String
  /** 关键字 */
  input?: String = ''

  onSearch() {
    this.$emit('search', this.input)
  }
}
