/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-report': {
    width: 95,
    height: 89,
    viewBox: '0 0 95 89',
    data: '<defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="svgicon_icon-report_b"><stop stop-color="#FAFAFB" offset="0%"/><stop stop-color="#F3F4F5" offset="100%"/></linearGradient><path pid="0" d="M20.53 0v18.482H0V90h71.25V27.322H53.136V0H20.53zM79.3 90H95V18.482H79.301V90z" id="svgicon_icon-report_a"/></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(0 -10)"><mask id="svgicon_icon-report_c" _fill="#fff"><use xlink:href="#svgicon_icon-report_a"/></mask><path pid="1" d="M20.53 10v18.482H0V100h71.25V37.322H53.136V10H20.53zM79.3 100H95V28.482H79.301V100z" _fill="url(#svgicon_icon-report_b)" mask="url(#svgicon_icon-report_c)"/></g><g transform="translate(20 28)"><rect pid="2" _stroke="#0064C8" stroke-width="2" stroke-linecap="round" x="13" width="30" height="7" rx="2"/><path pid="3" d="M4 5h47a4 4 0 014 4v48a4 4 0 01-4 4H4a4 4 0 01-4-4V9a4 4 0 014-4z" _fill="#E0E2E4"/><path pid="4" _stroke="#979797" _fill="#D8D8D8" d="M9.5 27.5h36v1h-36zM9.5 16.5h36v1h-36zM9.5 37.5h20v1h-20z"/><path pid="5" _stroke="#979797" _fill="#D8D8D8" opacity=".9" d="M9.5 46.5h20v1h-20z"/></g></g>'
  }
})
