import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import Components from '@/components'
import { initApi } from '@/utils/request'
import '@/icons/components'
import '@/permission'

initApi()
Vue.use(ElementUI)
Vue.use(Components) // 注册公共组件
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data() {
    return {
      // remoteAssets: 'https://web.xcheck360.com/assets',
      remoteAssets: '',
    }
  },
  render: (h: (arg0: any) => any) => h(App),
}).$mount('#app')
