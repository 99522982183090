import { alphaUrl, ajaxApi } from '@/utils/request';
import { QueryPage, QueryPageResult } from '.';

export interface IUserInfo {
  /** 姓名 */
  name: string;
  /** 头像 */
  avatar: string;
  roles?: string[]
  introduction?: string
  /** 邮箱 */
  email: string;
  /** 手机号 */
  phone: string
  position: string;
  /** 部门ID */
  officeId: string
  /** 账号ID */
  acctId: string
  /** 用户ID */
  userId: string
  /** 操作权限，00: 读写，01: 只读 */
  authType: '00' | '01'
  status: 0 | 1
}

type LoginInfo = {
  /** 用户名 */
  username: string
  /** 密码 */
  password: string
};

/** 获取用户信息 */
export const getUserInfo = () => ajaxApi('get', alphaUrl + '/acct/query/id') as Promise<IUserInfo>;
// export const getUserInfo = () => Promise.resolve({ name: '张三' } as IUserInfo);

/** 登录 */
export const login = (data: LoginInfo) =>
  ajaxApi('post', alphaUrl + '/p/login/user', { user: data.username, pwd: data.password }) as Promise<{
    token: string;
    username: string;
  }>;

/** 登出 */
export const logout = () => ajaxApi('get', alphaUrl + '/logout');

/** account */
type AccountQuery = {
  /** 部门id，为空时查询范围为全公司 */
  officeId: string
  /** 查询范围是否包含下级部门，默认为false */
  isChildrenInclude: boolean
};
type AccountQueryPage = QueryPage & Partial<AccountQuery>;

/** 查询部门所有账号信息 */
export const getAccountList = ({ officeId, isChildrenInclude }: AccountQuery) =>
  ajaxApi('get', alphaUrl + '/acct/query/all', { officeId, isChildrenInclude }) as Promise<IUserInfo[]>;

/** 分页查询部门的账号信息 */
export const getAccountPage = (params: AccountQueryPage) =>
  ajaxApi('get', alphaUrl + '/acct/query/page', params) as Promise<QueryPageResult<IUserInfo>>;

/** 游客信息 */
export interface TouristInfo {
  /** 姓名 */
  name: string;
  /** 公司名称 */
  company: string;
  /** 身份 */
  identity: string
  /** 邮箱 */
  email: string;
  /** 手机号 */
  phone: string
  /** 留言 */
  content: string;
}

export const saveTouristInfo = (data: TouristInfo) =>
  ajaxApi('post', alphaUrl + '/p/notice/contact', data) as Promise<{
    token: string;
    username: string;
  }>;
