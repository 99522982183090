
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'XSvgIcon',
})
export default class extends Vue {
  @Prop({ default: false }) name!: String

  get isElIcon() {
    return this.name.slice(0, 3) === 'el-'
  }

  get isImg() {
    return this.name.slice(0, 4) === 'img-'
  }
}
