
import { Component, Vue } from 'vue-property-decorator'
import ImgDialog from '@/components/ImgDialog.vue'
import { UserModule } from '@/store/modules'

@Component({
  name: 'XFooter',
  components: { ImgDialog },
})
export default class extends Vue {
  qrCodeVisible = false

  get imgUrl() {
    return UserModule.assistantInfo.wxQrUrl
  }

  /** 跳转到联系我们页面 */
  onLink() {
    this.$router.push({ name: 'contact' })
  }

  /** 打开地址 */
  openUrl(url: string) {
    window.open(url, '_blank')
  }

  /** 显示二维码弹窗 */
  showQrCode() {
    this.qrCodeVisible = true
  }
}
