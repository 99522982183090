
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'ImgDialog',
})
export default class extends Vue {
  /** 是否可见 */
  @Model('change', { type: Boolean, default: false }) visible!: boolean;
  /** 图片地址 */
  @Prop({ default: '' }) imgUrl!: string;

  mVisible = false;

  @Watch('visible')
  onVisibleChange(val: boolean) {
    if (this.mVisible !== val) this.mVisible = val;
  }

  @Watch('mVisible')
  onChange(val: boolean) {
    if (this.visible !== val) this.$emit('change', val);
  }
}
