
import { Component, Vue } from 'vue-property-decorator'
import { Personal } from '@/layout/components'
import { UserModule } from '@/store/modules'

@Component({
  name: 'XHeader',
  components: { Personal },
})
export default class extends Vue {
  get isLogin() {
    return !!UserModule.token
  }

  goLogin() {
    this.$router.push({ path: '/login' })
  }
}
