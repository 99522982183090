/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-qr-code': {
    width: 14,
    height: 15,
    viewBox: '0 0 14 15',
    data: '<path pid="0" d="M13.994 7.808v3.717H10.29V9.057H9.057v4.994H7.823V7.823h3.702v2.468h1.234V7.808h1.235zm-7.817.015v6.17H.007v-6.17h6.17zm7.817 4.936v1.235H10.29v-1.235h3.703zm-9.05-3.702H1.24v3.702h3.702V9.057zM3.708 10.29v1.234H2.475v-1.234h1.234zM6.177.006v6.171H.007V.007h6.17zm7.817 0v6.171H7.823V.007h6.17zm-9.05 1.235H1.24v3.702h3.702V1.241zm7.815 0H9.057v3.702h3.702V1.241zM3.71 2.475v1.234H2.475V2.475h1.234zm7.816 0v1.234h-1.234V2.475h1.234z" _fill="#B4BCC4" fill-rule="nonzero"/>'
  }
})
