/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'icon-users': {
    width: 16,
    height: 12,
    viewBox: '0 0 16 12',
    data: '<path pid="0" d="M7.274 6.3c1.597 0 2.905 1.26 2.905 2.798v1.498c0 .769-.654 1.399-1.453 1.399H1.464c-.799 0-1.453-.63-1.453-1.4V9.099c0-1.538 1.308-2.797 2.905-2.797h4.358zm5.81 1.4c1.597 0 2.905 1.258 2.905 2.797v.099c0 .769-.654 1.399-1.453 1.399h-2.905v-1.4h2.905v-.098c0-.77-.58-1.329-1.38-1.399h-1.525c0-.49-.145-.979-.363-1.399h1.816zm-5.81 0H2.916c-.799 0-1.38.559-1.452 1.328v1.568h7.262V9.098c0-.769-.58-1.329-1.38-1.399h-.072zm4.502-4.897c1.235 0 2.18.91 2.18 2.099S13.01 7 11.775 7c-1.234 0-2.178-.91-2.178-2.098 0-1.19 1.016-2.099 2.178-2.099zM5.095.005C6.693.005 8 1.265 8 2.803c0 1.54-1.307 2.798-2.905 2.798-1.598 0-2.905-1.259-2.905-2.798C2.19 1.264 3.497.005 5.095.005zm6.681 4.197c-.435 0-.726.28-.726.7 0 .42.29.7.726.7s.727-.28.727-.7c0-.42-.29-.7-.727-.7zM5.095 1.404c-.799 0-1.452.63-1.452 1.4 0 .769.653 1.398 1.452 1.398.799 0 1.453-.63 1.453-1.399 0-.77-.654-1.399-1.453-1.399z" _fill="#333" fill-rule="evenodd"/>'
  }
})
